@import '../../scss/variables.scss';
.Toggle_btn {
    display: none;
}
.headingMain {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: $color-dark;
    margin-bottom: 20px;
    line-height: 36px;
}

h2,
h3,
h4 {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: bold;
}

h2 {
    font-size: 28px;
    color: $color-primary;
    line-height: 32px;
}
h3 {
    font-size: 20px;
    color: $color-black;
    line-height: 50px;
}
h4 {
    font-weight: 600;
    font-size: 14px;
    color: #666;
    line-height: 40px;
}
p {
    font-weight: normal;
    font-size: 14px;
    color: #333;
    line-height: 24px;
}
ul {
    list-style: inherit;
    list-style-type: disc;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    line-height: 24px;
    margin-left: 24px;
}

@include max-screen($tablet-sm) {
    .Toggle_btn {
        display: block;
        font-size: 30px;
        color: $color-gray-dark;
    }
    h2 {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        color: $color-primary;
        line-height: 32px;
    }
}