@import '../../../scss/variables.scss';
.cards {
    background: $color-white;
    box-shadow: 0px 3px 9px rgba($color-black, 0.25);
    margin-top: 12px;
    padding: 20px;
    width: 30%;
    min-width: 280px;
    position: relative;
    height: auto;
}

.customModal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.customModal>p {
    font-weight: 600;
    font-size: 14px;
}

.mt30 {
    margin-top: 30px;
}

.mt25 {
    margin-top: 25px;
}

.comingSoon {
    padding: 10px;
    background-color: $color-warning-light;
    display: inline;
}

.react-responsive-modal-modal {
    max-width: 620px;
    height: 320px;
    max-height: 825px !important;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    background: $color-white;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
    margin: 1rem;
    padding: 1.5rem;
    position: relative;
    overflow-y: auto;
    @include max-screen(827px) {
        width: 100vw;
    }
}

.pacModal {
    width: 162px;
    height: 115px;
}

.pavlov {
    width: 59px;
    margin-left: 7px;
    margin-bottom: 3px;
    transform: rotate(-13deg);
}

.dashHead {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    p {
        margin-bottom: 0;
    }
    img {
        width: 50px;
        margin-left: 10px;
    }
}
.tablevalue {
    >td {
        text-align: center;
        padding: 16px;
        font-size: 15px;
        font-weight: 700;
    }
    >.hoverBtn:hover {
        cursor: pointer;
        text-decoration-line: underline;
    }
}

.bottomLogoNav {
    width: 140px;
    height: auto;
    margin-left: 60px;
}

.card-text {
    font-weight: 600;
    font-size: 12px;
    margin-top: 12px;
    line-height: 20px;
}

.bottom {
    /* position: absolute; */
    /* bottom: 7%; */

    /* overflow-wrap: break-word; */
    text-align: center;
}

table>tr>th {
    background-color: $color-primary !important;
    color: white !important;
}

table {
    border: 1px solid $color-primary-light;
}

.section {
    overflow-x: auto !important;
}

.doughnut-div {
    width: 50%;
    max-width: 150px;
    margin: auto;
}

.doughnut-div-main {
    display: flex;
}

.percent-value {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    font-family: $font-family-base;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
}

.month {
    text-align: center;
    font-size: 20px;
}

.totalHours {
    text-align: center;
}

.font {
    color: $color-primary;
    font-size: 12px;
}

.withoutcolor {
    font-size: 12px;
}

.colored {
    /* color: $color-primary; */
    margin-bottom: -3px;
}

.hidden {
    visibility: hidden;
}

.monthCard {
    padding: 20px;
    width: 45%;
    height: 336px;
    box-shadow: 0px 3px 9px rgba($color-black, 0.25);
    @include max-screen(1070px) {
        height: 366px;
    }
    @include max-screen(945px) {
        height: 386px;
    }
    @include max-screen(827px) {
        height: 406px;
    }
    @include max-screen(807px) {
        height: 425px;
    }
    @include max-screen(790px) {
        height: 475px;
    }
    @include max-screen(728px) {
        height: 575px;
    }
    @include max-screen(635px) {
        height: 675px;
    }
    @include max-screen(594px) {
        height: 805px;
    }
}

.unordered-list>li {
    list-style: initial;
    margin-bottom: 0.5rem;
}

.upload-button-table {
    width: 110px;
    font-family: $font-family-base;
    text-decoration: underline;
    font-weight: 600;
}

.upload-button-table>svg {
    margin-right: 3px;
}

.upload-button-table:hover {
    cursor: pointer;
}

.card-dashboard {
    margin-right: 1rem;
    padding: 1.5rem;
    box-shadow: 0px 3px 9px rgba($color-black, 0.25);
    >h2 {
        color: $color-darken;
        margin-bottom: 10px;
    }
}

.Final-Field-container {
    margin-right: 1.5rem;
    display: flex;
    flex-direction: column;
    >h2 {
        margin-bottom: 10px;
    }
    >button {
        background: $color-primary;
        border-radius: 30px;
        width: 160px;
        height: 40px;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        color: $color-white;
        margin: 15px 0;
        cursor: pointer;
    }
}

.agendaTable {
    width: 100%;
    overflow-x: auto;
}
.captionWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .dashHead {
        margin: 0;
        @include max-screen(768px) {
            width: 100%;
            margin-bottom: 20px;
        }
        img {
            @include max-screen(635px) {
                width: 40px;
            }
        }
    }
    .headingMain {
        @include max-screen(635px) {
            font-size: 26px !important;
        }
    }
}

.faq-wrap {
    display: flex;
    align-items: center;
}

.faq-container {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $color-primary;
    border-radius: 10px;
    padding: 5px 10px;
    text-decoration: none;
    background-color: rgba($color-primary, 0.1);
    img {
        margin-left: 10px;
        width: 20px;
    }
    &:hover {
        color: $color-primary;
    }
}
.headMain {
    @include max-screen(790px) {
        font-size: 24px;
    }
}

.menuIcon {
    min-width: 22px;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6e6e6;
    transition: all 0.2s;
    cursor: pointer;
    &:hover {
        background-color: #cccccc;
    }
}