@import '../scss/variables.scss';
.btnself {
    width: 510px;
    @include max-screen(1300px) {
        width: 480px
    }
    @include max-screen(1260px) {
        width: 450px
    }
    @include max-screen(1190px) {
        width: 400px
    }
    @include max-screen(1090px) {
        width: 380px
    }
    @include max-screen(970px) {
        width: 360px
    }
    @include max-screen(900px) {
        width: 320px
    }
    @include max-screen(490px) {
        width: 220px
    }
}
.apply-btn {
    width: auto !important;
    margin: 20px 0 0 !important;
}
.coupon-label {
    margin-bottom: 0 !important;
    @include max-screen($mobile) {
        margin-bottom: 17px !important;
    }
}