@import '../../scss/variables.scss';
.error {
    margin-left: 5%;
    color: $color-danger;
}

.subsCard {
    display: flex;
}

.card-input-element {
    display: none;
}

.comingSoon {
    padding: 10px 20px;
    background-color: $color-warning-light;
    display: inline-block;
    text-align: center;
    margin: auto;
}

.panel-head {
    width: 287px;
    height: 217px;
    padding: 30px;
    border: 2px solid $color-primary;
    box-sizing: border-box;
    border-radius: 20px;
}

.continue-button {
    width: 320px;
    @include max-screen(447px) {
        width: 220px;
    }
}

.container {
    max-width: 900px;
    margin: 0 auto;
}

.Subscription-text {
    color: $color-darken;
    font-family: $font-family-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 65px;
    text-align: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    @include min-max-screen(1100px, 1490px) {
        line-height: 105px;
    }
    @include max-screen(780px) {
        color: $color-danger;
    }
}

.title-container-subs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.intro-text {
    width: 70%;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    margin: 1rem 0;
    line-height: 21px;
}

/**
    Component
  **/

label {
    width: 100%;
}

.card-input-element-current {
    display: none;
}

.card-input-element {
    display: none;
}

.card-input {
    margin: 10px;
    padding: 00px;
    background-color: $color-white;
    &:hover {
        cursor: pointer;
    }
}


.card-input-element-current {
    &+.card-input {
        background-color: #fcf4f7;
        box-sizing: border-box;
        border-radius: 20px !important;
        .title {
            color: $color-primary;
        }
    }
}


.card-input-element {
    &:checked {
        &+.card-input {
            background: $color-primary;
            box-sizing: border-box;
            border-radius: 20px !important;
            .title,
            .subtitle,
            .subtitle2 {
                color: $color-white;
            }
            .panel-head {
                border-radius: none;
            }
        }
    }
}

.subtitle {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $color-darken;
}

.subtitle2 {
    font-family: $font-family-base;
    font-style: normal;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: $color-darken;
}

.title {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 29px;
    text-align: center;
    color: $color-primary;
}

.flexd {
    display: flex;
}

.register-form-layout1 {
    margin-left: 18%;
    @include min-max-screen(770px, 1490px) {
        margin-left: 24%;
    }
    @include min-max-screen(548px, 768px) {
        margin-left: 0%;
    }
}

.mt120 {
    margin-top: 120px;
}

.btn-trial:disabled {
    color: #14141450;
    border: 2px solid #d39fb250;
}

.price {
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-family: $font-family-base;
    font-size: 55px;
}

.price sub {
    margin-top: 0.5rem;
}

.checkBlock {
    position: relative;
    input {
        left: 0;
        top: 0;
        opacity: 0;
        position: absolute;
        &:checked+span::before {
            background-color: $color-primary;
            border-color: $color-primary;
            @include background-image(check, svg, center, no-repeat, 16px);
        }
    }
    span {
        font-family: $font-family-base;
        font-size: 17px;
        line-height: 22px;
        color: #383737;
        position: relative;
        user-select: none;
        padding-left: 30px;
        &::before {
            content: "";
            width: 20px;
            height: 20px;
            display: block;
            left: 0;
            top: 1px;
            cursor: pointer;
            border-radius: 3px;
            position: absolute;
            background-color: $color-white;
            border: 1px solid $color-black;
        }
    }
}

.size_btn {
    @include min-max-screen(1100px, 1490px) {
        width: 300px;
    }
    @include min-max-screen(770px, 1080px) {
        width: 300px;
    }
}

.isOverlay {
    z-index: 1;
    position: relative;
    &::before {
        @include max-screen(1299px) {
            content: "";
            left: 0;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba($color-white, 0.7);
        }
    }
}
.forgot-password-text-sub {
    @include min-max-screen(548px, 768px) {
        margin-left: 27px;
    }
}
.subscription-wrap {
    .column {
        &:empty {
            @include max-screen($tablet) {
                display: none;
            }
        }
    }
}