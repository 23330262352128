@import '../../../scss/variables.scss';
.paymentInputDivDash {
    display: flex;
}
.input-card {
    width: 100%;
    border: 1px solid $color-primary;
    box-sizing: border-box;
    box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
    border-radius: 4px;
    background-color: $color-white;
    line-height: 1.5;
    height: 2.5em;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    &:focus {
        border-color: #d39fb2;
        box-shadow: 0 0 0 0.125em rgba(184, 100, 149, 0.25);
    }
}

.input-card:hover {
    border-color: #b5b5b5;
}
.customInput3,
.customInput4 {
    width: 100%;
}
.mt20 {
    margin-top: 20px;
}
.paymentInputDashDiv {
    display: flex;
    margin-left: 0% !important;
    margin-bottom: 20px;
}
.ml10 {
    margin-left: 18px;
}
.offer-title {
    font-size: 20px;
}
.offer-info {
    display: flex;
    align-items: center;
}
.offer-info p {
    font-size: 20px;
    margin-right: 10px;
}
.offer-info-old {
    opacity: 0.5;
}
p.offer-info-price {
    font-size: 40px;
}
.offer-info-off {
    color: $color-primary;
}
.column {
    &.is-two-fifths {
        @include max-screen(1490px) {
            flex: none;
        }
        @include min-max-screen(1100px, 1490px) {
            width: 65% !important;
        }
        @include min-max-screen(770px, 1080px) {
            width: 80% !important;
        }
        @include min-max-screen(548px, 768px) {
            width: 76% !important;
        }
        @include min-max-screen(425px, 700px) {
            width: 90% !important;
        }
        @include max-screen(420px) {
            width: 100% !important;
        }
    }
}
.customInput1,
.customInput2 {
    @include max-screen(1080px) {
        width: 158px;
    }
    @include max-screen(420px) {
        width: 150px;
    }
    @include max-screen(360px) {
        width: 125px;
    }
}