@import '../../scss/variables.scss';
.Register__error {
    margin-left: 1%;
    color: $color-danger;
}

.text-sub {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $color-primary;
}
.email-verify {
    max-width: 400px;
    margin: 30px auto 0;
}