@import '../../../scss/variables.scss';
.dashForm {
    >label {
        color: $color-black;
        margin-left: 0px !important;
        font-size: 14px;
        line-height: 17px;
        font-family: $font-family-base;
        font-style: normal;
    }
}
.flagged {
    color: $color-danger;
    margin-left: 3px !important;
    font-size: 11px;
    line-height: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: italic;
}