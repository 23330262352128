@import '../../scss/variables.scss';
.headingMain {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: $color-dark;
    margin-bottom: 20px;
}
h2 {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: $color-primary;

    line-height: 32px;
}
h3 {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: $color-black;
    line-height: 50px;
}
h4 {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #666;
    line-height: 40px;
}
p {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    line-height: 24px;
}
ol {
    list-style: inherit;
    list-style-type: disc;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    line-height: 24px;
    margin-left: 24px;
}