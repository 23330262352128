@import '../../../scss/variables.scss';
input[type="checkbox"] {
    vertical-align: middle;
}

.selectBtn {
    appearance: none;
    display: inline;
}

.grid-top {
    display: flex;
    justify-content: space-between;
    @include max-screen($mobile) {
        flex-wrap: wrap;
    }
}

.color-scale {
    display: flex;
}

.color-scale>div.pill {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 19px;
    border-radius: 8px;
    font-size: 10px;
    margin-right: 4px;
}

.column>p.instruct {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: 2rem 0;
    color: #767676;
    font-weight: 400;
    text-align: center;
}

.content-item {
    display: flex;
}
.toogle-btnMI {
    display: flex;
    align-items: center;
    width: 3%;
}
.toogle-btnMI:hover {
    cursor: pointer;
}
.list-item {
    display: flex;
    flex-direction: column;
    background: $color-white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem 0;
    width: 97%;
}
.list-item>.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.top>.left {
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    background: $color-light;
    border-radius: 8px;
    width: 8%;
    font-weight: 700;
    font-size: 22px;
}
.top>.center {
    width: 84%;
    padding: 0 1rem;
}
.top>.center>.date {
    font-weight: 700;
    color: #838383;
    font-size: 12px;
    margin-bottom: 0.3rem;
}
.top>.right {
    display: flex;
    justify-content: flex-end;
    width: 8%;
}

.list-item>.bottom {
    display: flex;
}

.list-item>.bottom>.container {
    padding: 1rem 0;
}

.monthlyTable>tr>td {}

.monthlyTable>tr>td.customColumn {
    /*border-left: 1px solid red;
    border-right: 1px solid red;*/
}

.monthlyTable>tr>td>select,
.monthlyTable>tr>td>div.selectBtn {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    width: 70px;
    height: 30px;
    border: 1px solid #dcd9d9 !important;
    box-sizing: border-box;
    border-radius: 5px;
    color: $color-dark;
}

div.selectBtn {
    display: inline-block !important;
    height: 35px !important;
    position: relative;
    vertical-align: top;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    line-height: 35px;
}

div.selectBtn>div {
    display: none;
    position: absolute;
    border: 1px solid black;
    z-index: 100;
    min-width: 140px;
}

div.selectBtn>div ul {
    margin-left: 0px;
    list-style-type: none;
}

div.selectBtn:hover>div {
    display: block;
}

.checkSize {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border: 1px solid $color-black;
    box-sizing: border-box;
    vertical-align: middle;
    margin: 10px 10px;
    margin-left: 0;
}

.select:not(.is-multiple) {
    height: 35px;
}

.HRline {
    width: 100%;
    height: 0px;
    border: 1px solid $color-dark;
    margin-bottom: 20px;
}

.clr1 {
    background: $color-warning;
    border: 1px solid $color-warning;
    box-sizing: border-box;
    border-radius: 5px;
    width: 68px;
    margin: 5px 15px;
    height: 22px;
}

.clr2 {
    background: $color-info;
    border: 1px solid $color-info;
    box-sizing: border-box;
    border-radius: 5px;
    width: 68px;
    height: 22px;
    margin: 5px 15px;
}

.clr3 {
    background: $color-primary;
    border: 1px solid $color-primary;
    box-sizing: border-box;
    border-radius: 5px;
    width: 68px;
    height: 22px;
    margin: 5px 15px;
}

.secondSectionMonthlyDocumnet {
    display: flex;
    align-items: center;
    @include min-max-screen(320px, 1080px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    >div {
        >p {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            color: $color-dark;
        }
    }
}

.ml160 {
    margin-left: 200px;
    @include min-max-screen(320px, 1080px) {
        margin-left: 0px;
    }
}

.mt10 {
    margin-top: 10px;
}
.colordtext {
    >div {
        >span {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: $color-dark;
            padding: 0px;
        }
    }
}

.Heading {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    color: $color-dark;
}

.mt5 {
    margin-top: 5px;
}

.headerChart {
    width: 100%;
    height: 96px;
    text-align: center;
    display: flex;
    background: $color-primary;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);

    background: $color-primary;
}

.headerChart>p {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    font-variant: small-caps;
    text-align: center;
    color: $color-white;
}

.contactAgendaInput {
    border: none;
    background-color: transparent;
    margin-left: 10px;
    font-size: 14px;
    font-weight: normal;
}

.agendaSec1 {
    display: flex;
    flex-wrap: wrap;
    >div {
        >p {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 15px;
            color: $color-darken;
            margin: 20px;
        }
    }
}


.saveBtn {
    background-color: $color-primary;
    color: $color-white;
    width: 200px;
}

.checkbox-label {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: $color-darken;
    margin: 20px;
    >span {
        vertical-align: middle;
    }
}


.subHeading {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    color: $color-darken;
}

.agendasec2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.agenda-table {
    >tr {
        >td,
        >th {
            font-family: $font-family-base;
            font-size: 13px;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
        }
        >th {
            font-weight: 600;
        }
        >td {
            font-weight: 500;
        }
    }
}


.colSize1 {
    width: 100px;
    text-align: center;
}

.checkText {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    color: $color-darken;
}

.textareA {
    width: 100%;
}

table>tr>td {
    color: black;
}

label {
    color: black;
}

.dropdownCustomize {
    width: 40%;
}

p,
label {
    color: black;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.inputagenda {
    border: none;
    border-bottom: 1px solid $color-border;
    margin-left: 15px;
}

.check {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
}

.check>* {
    width: 50%;
    height: 30px;
}

.check>p {
    padding: 10px;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    width: 320px;
    font-size: 13px;
    /* identical to box height */

    color: $color-darken;
}

.inputFlex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.inputagenda2 {
    border: none;
    border-bottom: 1px solid $color-border;
    width: 320px;
}
.inputagenda3 {
    border: none;
    border-bottom: 1px solid $color-border;
    width: 100%;
    height: 30px;
}

.agendaTable {
    >table {
        >tr {
            >td {
                height: 25px;
                font-family: $font-family-base;
                font-size: 12px;
                font-weight: 500;
                line-height: 13px;
                letter-spacing: 0em;
                text-align: center;
                color: $color-darken;
                vertical-align: middle;
            }
            >th {
                height: 30px;
                text-align: center;
                vertical-align: middle;
            }
        }
    }
}

.pro-sidebar {
    .pro-menu {
        .pro-menu-item {
            >.pro-inner-item {
                >.pro-icon-wrapper {
                    margin-right: 10px;
                    font-size: 20px;
                    width: 35px;
                    min-width: 35px;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    display: inline-block;
                }
                >.pro-item-content {
                    flex-grow: 1;
                    font-size: 16px;
                    flex-shrink: 1;
                }
            }
            &.active {
                color: $color-dark;
                font-weight: bold;
            }
        }
    }
}

.agendaTableHeader {
    font-family: $font-family-base;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: $color-darken;
    vertical-align: middle;
}

.agendaTableText {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: $color-darken;
}

.topping {
    display: flex;
    margin: 1rem 0;
}

.topping {
    >div {
        border: 1px solid $color-gray-medium;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        font-weight: 600;
        &:hover {
            cursor: pointer;
            transition: 0.2s;
            -webkit-box-shadow: 0 0 0 0.125em rgba(164, 164, 164, 0.25);
            box-shadow: 0 0 0 0.125em rgba(164, 164, 164, 0.25);
        }
    }
}

.dropdownCustomize {
    @include max-screen(715px) {
        width: unset;
    }
}

.inputagenda {
    @include max-screen(350px) {
        width: 100%;
    }
}

.saveBtn {
    @include max-screen(350px) {
        width: 80%;
    }
}

.dropdownCustomize2 {
    width: 100px;
    height: 20px;
    font-size: 14px;
    border: none;
    vertical-align: unset;
    line-height: 14px;
    margin-left: 10px;
}
.monthlyTableMove {
    @include max-screen($tablet) {
        width: 100% !important;
        overflow-x: auto;
    }
}
.monthly-caption {
    @include max-screen($mobile) {
        display: flex;
        align-items: center;
        margin: 20px 0 !important;
    }
    button {
        @include max-screen($mobile) {
            margin: 0 !important;
        }
        svg {
            @include max-screen($mobile) {
                margin: 0 !important;
                display: block;
            }
        }
    }
}