@import '../../scss/variables.scss';
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid $color-gray-lighten;
    text-align: left;
    padding: 8px;
}
table>tr>th {
    background: $color-primary-light;
    border: none;
    text-align: center;
}

.flagtable__container {
    max-width: 60%;
    @include max-screen($tablet){
        max-width: 100%;
    }
}

.Flagtable>tr>th {
    background-color: $color-primary-light;
    border: none;
    text-align: center;
}
.tableFlex {
    display: flex;
    justify-content: space-between;
}
.statusSpanTable {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin-left: 2rem;
    /* identical to box height */

    color: $color-primary;
}

.editSpanTable {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: $color-primary;
}
.barTableHeader {
    background: $color-primary;
    border: 1px solid $color-border;
    box-sizing: border-box;
}
.colSize {
    width: 40px;
}
table>tr>td {
    background-color: white;
}

.empty-list {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-gray;
}