@import '../../scss/variables.scss';
.error {
    margin-left: 5%;
    color: $color-danger;
}

.customInput1 {
    width: 215px;
}
.customInput2 {
    width: 215px;
}
.customInput5 {
    width: 187px;
    @include min-max-screen(770px, 1080px) {
        width: 140px;
    }
    @include min-max-screen(548px, 768px) {
        width: 335px;
    }
}
.customInput6 {
    width: 187px;
    @include min-max-screen(770px, 1080px) {
        width: 140px;
    }
    @include min-max-screen(548px, 768px) {
        width: 335px;
    }
}

.paymentInputDiv {
    display: flex;
    margin-bottom: 20px;
    @include min-max-screen(1100px, 1490px) {
        margin-left: 20% !important;
    }
    @include min-max-screen(770px, 1080px) {
        margin-left: 25% !important;
    }
    @include min-max-screen(548px, 768px) {
        margin-left: -2% !important;
    }
    >div {
        margin-left: 0px;
    }
}

.paymentCancelText {
    margin-left: 30%;
    @include min-max-screen(1100px, 1490px) {
        margin-left: 27% !important;
    }
    @include min-max-screen(770px, 1080px) {
        margin-left: 57% !important;
    }
    @include min-max-screen(548px, 768px) {
        margin-left: 48% !important;
    }
}
.addPaymentText {
    color: $color-dark;
    font-family: $font-family-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 65px;
    align-self: center;
    text-align: center;
    justify-content: center;
    align-items: center;
    @include max-screen($mobile) {
        font-size: 40px;
    }
}
.ml0 {
    margin-left: 0% !important;
}

.column {
    &.is-half-tablet,
    &.is-half {
        @include min-max-screen(1100px, 1490px) {
            flex: none;
            width: 82%;
        }
        @include min-max-screen(548px, 768px) {
            flex: none;
            width: 95%;
        }
    }
    &.is-half {
        @include min-max-screen(770px, 1080px) {
            flex: none;
            width: 100%;
        }
    }
}