@import '../scss/variables.scss';
.attachment {
    background: lighten($color-black, 93.33);
    border: 1px dashed lighten($color-black, 76.86);
    box-sizing: border-box;
    width: 530px;
    height: 150px;
}
.sidebar-logo {
    width: 180px;
    display: block;
    margin: 20px auto 0;
}
.add-files {
    text-align: center;
    padding: 1rem;
}
.DragText {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: $color-black;
}
.DropText {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 28px;
    text-align: center;
    color: $color-dark;
}

.sidebar-accrued-table {
    margin: 30px 20px;
}
.sidebar-accrued-table {
    .agenda-table {
        >tr {
            >th {
                font-family: $font-family-base;
                font-size: 12px;
                font-weight: 600;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                width: 70%;
            }
            >td {
                font-family: $font-family-base;
                font-size: 12px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                width: 70%;
            }
        }
    }
}

.subscription-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    max-width: 500px;
    width: calc(100% - 30px);
    height: 400px;
    background-color: $color-white;
    box-shadow: 0px 3px 9px 0px rgba($color-black, 0.251);
    padding: 0 5%;
    >h1 {
        font-family: $font-family-base;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: center;
        color: $color-darken;
        margin: 0;
    }
    .buttons {
        width: 100%;
    }
    .continue-button {
        background: $color-primary;
        border-radius: 30px;
        width: 100%;
        height: 60px;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $color-white;
        margin-bottom: 20px;
    }
    .back-button {
        background: $color-dark;
        border-radius: 30px;
        width: 100%;
        height: 60px;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $color-white;
    }
}

.tooltip-content {
    font-family: $font-family-base;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: $color-white;
    white-space: pre-wrap;
}

.upload-container {
    display: flex;
    flex-direction: column;
}

.upload-container {
    >h3 {
        text-align: center;
    }
}

.checkbox-label-pink {
    font-family: $font-family-base;
    font-style: normal;
    font-size: 14px;
    color: $color-darken;
    margin: 5px 0 0 0;
    font-weight: 500;
}

.checkbox-label-pink {
    >span {
        vertical-align: middle;
    }
}

input[type="checkbox"] {
    --checkbox-color: $color-primary !important;
}

input[type="checkbox"]::before {
    background-color: var(--checkbox-color) !important;
    border-color: var(--checkbox-color) !important;
}

.checkSize-pink {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border: 1px solid $color-black;
    box-sizing: border-box;
    vertical-align: middle;
    margin-left: 0;
    background-color: $color-primary;
}

.checkbox-label-pink {
    >.checkSize-pink {
        &:checked::before {
            background-color: $color-primary;
        }
    }
}

.checkbox-label-pink {
    >span {
        font-weight: bold;
    }
}

.column-input {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.column-input {
    >label {
        color: $color-darken;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        margin-left: 0;
    }
}

.primary-button-modal {
    height: 42px;
    width: 48%;
    background: $color-primary;
    border-radius: 30px;
    color: $color-white;
    font-family: $font-family-base;
    font-size: 18px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.secondary-button-modal {
    height: 42px;
    background: $color-dark;
    border-radius: 30px;
    color: $color-white;
    font-family: $font-family-base;
    font-size: 18px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0 1rem;
}

.simple-button-modal {
    color: $color-primary;
    text-decoration: underline;
    padding: 0 1rem;
    font-weight: 700;
    font-family: $font-family-base;
    font-size: 14px;
}
.simple-button-modal:hover {
    cursor: pointer;
}

.task_data__date-container {
    display: flex;
}

.task_data__date-container {
    >span {
        display: flex;
        padding: 0 1rem;
        align-content: center;
        font-size: 2rem;
    }
}