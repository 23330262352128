@import '../../scss/variables.scss';
.has-background {
    min-height: 100vh;
    // background-color: #fafbfd;
    @include background-image(bg-right, png, right center, no-repeat, auto 100%);
    @include max-screen($mobile) {
        @include background-image(bg-right-light, png, right center, no-repeat, auto 100%);
    }
}

.has-light-background {
    @include background-image(bg-right, png, right center, no-repeat, auto 100%);
    min-height: 100vh;
    @include max-screen($mobile) {
        @include background-image(bg-right-light, png, right center, no-repeat, auto 100%);
    }
}

.section {
    width: 100%;
    padding: 3rem 6rem;
    @include max-screen($desktop) {
        padding: 40px;
    }
    @include max-screen($tablet) {
        padding: 30px;
    }
    @include max-screen(780px) {
        padding: 20px
    }
}

.has-left-background {
    // background-color: #fafbfd;
    min-height: 100vh;
    @include background-image(bg-left, png, left center, no-repeat, auto 100%);
    @include max-screen($mobile) {
        @include background-image(bg-left-light, png, left center, no-repeat, auto 100%);
    }
}

.has-left-light-background {
    min-height: 100vh;
    @include background-image(bg-left, png, left center, no-repeat, auto 100%);
    @include max-screen($mobile) {
        @include background-image(bg-left-light, png, left center, no-repeat, auto 100%);
    }

}

.row-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.remember-me-layout {
    margin-left: 4%;
    width: 100vh;
    color: $color-darken;
}

.forgot-pass {
    color: $color-darken;
    text-decoration: underline;
    width: 100%;
}

p.headingMain {
    text-align: center;
}

.welcome-text {
    width: 100%;
    font-family: $font-family-secondary;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    font-size: 48px;
    line-height: 63px;
    color: $color-darken;
    @include max-screen(420px) {
        font-size: 35px;
    }
}

.registration-text {
    color: $color-darken;
    font-family: $font-family-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    text-align: center;
    line-height: 63px;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.register-image {
    width: 200px;
    margin: 10px auto 30px;
    @include max-screen(780px) {
        padding: 15px;
        background-color: whitesmoke;
        border-radius: 12px;
    }
}

.label {
    color: $color-darken;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    margin-left: 15px;
}

.has-input-background {
    box-sizing: border-box;
    border: 1px solid #939597;
    border-radius: 5px;
}

.signin-button {
    background: $color-primary;
    border-radius: 30px;
    width: 100%;
    height: 60px;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    color: $color-white;
}

.signup-text {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: $color-darken;
    @include max-screen(780px) {
        color: $color-danger;
    }
}

.bottom-text-div {
    text-align: center;
}

.log-text {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: $color-darken;
    @include max-screen(780px) {
        color: $color-danger;
    }
    @include min-max-screen(320px, 360px) {
        font-size: 14px;
    }
}

.Logo {
    width: 200px;
    margin: 10px auto 30px;
    @include max-screen(780px) {
        background-color: whitesmoke;
        border-radius: 12px;
        padding: 15px;
        margin: 0 auto;
    }
}

.row-flex {
    .checkbox {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 500;
        color: $color-darken;
        font-size: 12px;
        margin-left: 10px;
    }
}

.customFlex {
    width: 500px;
    @include min-max-screen(1100px, 1490px) {
        width: 307px;
    }
    @include min-max-screen(770px, 1080px) {
        width: 600px;
        margin-left: -12px;
    }
    @include max-screen(768px) {
        width: 307px;
    }
}
.forgot-password-text-sub {
    @include min-max-screen(1100px, 1490px) {
        margin-left: 63px;
    }
    @include min-max-screen(770px, 1080px) {
        margin-left: 50px !important;
    }
    @include max-screen(700px) {
        font-size: 12px;
        line-height: 15px;
        margin-left: 10px !important;
    }
}
.column {
    &.is-one-quarter,
    &.is-one-quarter-tablet {
        @include min-max-screen(770px, 1080px) {
            flex: none;
            width: 45%;
        }
    }
    &.is-one-third {
        @include min-max-screen(770px, 1080px) {
            width: 45%;
        }
    }
}

.register-checkbox-text {
    @include max-screen(780px) {
        color: $color-danger;
    }
}

.form-layout {
    @include max-screen(700px) {
        margin-left: 0%;
    }
}