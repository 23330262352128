@import '../../../scss/variables.scss';
.add-supervisor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-supervisor-btn {
    background-color: $color-primary;
    color: $color-white;
    padding: 0 30px;
}

.justify-end {
    display: flex;
    justify-content: end;
}

.add-supervisor-btn-invite {
    >p {
        color: $color-primary;
        text-decoration: underline;
        cursor: pointer;
    }
}

.add-supervisor-btn-delete {
    margin-top: 30px;
    color: $color-primary;
    text-decoration: underline;
    font-size: 18px;
}

.AddSupervisorTable {
    .trackerAccount {
        display: flex;
        justify-content: center;
    }
}
.supervisor-dialog {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 35%;
    background-color: white;
    box-shadow: 0px 3px 9px 0px #00000040;
    padding: 68px 5vw;
    @include max-screen(1200px) {
        padding: 50px 30px;
    }
    .close-btn {
        position: absolute;
        top: 30px;
        left: 30px;
        border: none;
        cursor: pointer;
        color: #6c6c6c;
    }
    >h1 {
        font-family: $font-family-base;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: center;
        color: $color-darken;
        margin: 0;
    }
    .buttons {
        width: 100%;
    }
    .continue-button {
        background: $color-primary;
        border-radius: 30px;
        width: 100%;
        height: 60px;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $color-white;
        margin-bottom: 20px;
    }
    .back-button {
        background: $color-dark;
        border-radius: 30px;
        width: 100%;
        height: 60px;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $color-white;
    }

}
.send-invitation-msg {
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
}
.edit-button {
    cursor: pointer;
}