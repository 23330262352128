@import '../../scss/variables.scss';
.sectiondashboard {
    .mtPrompt {
        margin-top: 240px;
    }
    .promptText {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $color-primary;
    }
    .sidebar-logo {
        width: 180px;
        display: block;
        margin: 20px auto 0;
    }
}