@import '../../scss/variables.scss';
.splash-has-background {
    @include background-image(bg-left, png, left center, no-repeat, auto 100%);
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include max-screen($mobile) {
        grid-template-columns: 1fr;
        background-image: none;
    }
}

.splash-left {
    order: 2;
    position: relative;
    background-color: #f3f3f3;
}

.splash-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 172px;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    @include max-screen($tablet) {
        height: auto;
        padding: 10px 0;
    }
    @include max-screen($mobile) {
        display: none;
    }
}

.splash-bottom-mobile {
    display: none;
    @include max-screen($mobile) {
        width: 100%;
        height: 142px;
        background-color: #ecdfe7;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.splash-colab-bottom {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-left: 2rem;
    @include max-screen($tablet) {
        margin-left: 0;
    }
}

.splash-image-logo {
    width: 170px;
}

.splash-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 3rem;
    padding-bottom: 142px;
    @include max-screen($tablet) {
        padding: 20px;
        height: auto;
    }
}

.splash-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: $color-darkest;
    text-align: left;
    width: 80%;
    line-height: 1.25;
    @include max-screen($tablet) {
        width: 100%;
    }
}

.splash-title label {
    font-size: 2.5rem;
    font-weight: 700;
    color: $color-primary;
}

.splash-subtitle {
    font-weight: 700;
    font-size: 1rem;
    width: 80%;
    line-height: 26px;
    padding: 1rem 0;
    text-align: left;
    @include max-screen($tablet) {
        width: 100%;
    }
}

.splash-subtitle label {
    color: $color-primary;
    font-weight: 700;
    font-size: 1rem;
}

.splash-textContent {
    width: 80%;
    font-size: 0.8rem;
    color: $color-darkest;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    @include max-screen($tablet) {
        width: 100%;
    }
}

.splash-colab-bottom {
    .splash-textContent {
        width: 100%;
    }
}

.splash-right {
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
    justify-content: center;
    align-items: center;
    order: 1;
    @include max-screen($tablet) {
        margin-left: 0;
    }
}

.container-splash {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5rem;
    border-radius: 2rem;
    width: 300px;
}

.splash-question {
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
    margin-top: 1.3rem;
}

.splash-q-r {
    font-size: 16px;
    font-weight: 500;
    margin-top: 0.8rem;
    margin-bottom: 0;
    text-align: center;
}

.sun-splash {
    height: 15%;
    margin-bottom: 0.5rem;
}

.splash-text-right {
    margin-bottom: 0.5rem;
}

.is-horizontal-center {
    justify-content: center;
}

.column {
    padding-top: 0px;
}

.bottom-logo {
    width: 231px !important;
    height: 80px;
    @include min-max-screen(1100px, 1490px) {
        width: 251px !important;
    }
}

.role-button {
    width: 15rem;
    border: none;
}

.role-button-student {
    background-color: $color-primary;
    color: $color-white;
}

.role-button-trainee {
    background-color: $color-dark;
    color: $color-white;
    &:hover {
        background-color: $color-dark;
        color: $color-primary;
    }
}


.button-container {
    height: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

button {
    background: none;
    border: none;
    outline: inherit;
    font-size: 12px;
}
.log {
    margin-left: 30px;
    @include min-max-screen(1100px, 1490px) {
        width: 500px !important;
    }
    @include max-screen(700px) {
        margin-left: 0px;
    }
}