html:has(body.isHidden) {
    overflow: hidden !important;
}
body {
    margin: 0;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
}

div#root {
    min-width: 100vw;
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

iframe {
    display: none;
    z-index: -20;
}
button {
    cursor: pointer;
}