@import '../../../scss/variables.scss';
input[type="radio"] {
    vertical-align: middle;
}
.column {
    &.is-one-fifths,
    &.is-one-fifths-tablet {
        flex: none;
    }
    p {
        &.instruct.supervisor {
            margin-bottom: 20px;
        }
    }
}
.monthly-document {
    .label {
        font-family: $font-family-base;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: $color-darken;
        margin-left: 0;
        margin-top: 60px;
    }
    .dropdownCustom {
        box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
        max-width: 100%;
        width: 100%;
        background-color: $color-white;
        border-color: $color-gray-dark;
        border-radius: 5px;
        color: $color-darken;
        height: 36px;
        padding: 0 10px;
        font-family: $font-family-base;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 40px;
    }
    .dropdownCustom2 {
        width: 250px;
        background-color: $color-white;
        border: none;
        color: $color-darken;
        font-family: $font-family-base;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        padding: 0;
        height: fit-content;
    }
}

input[type="checkbox"] {
    vertical-align: middle;
}
.selectBtn {
    appearance: none;
    display: inline;
}

.monthlyTable {
    >tr {
        >td {
            border: none;
            select,
            div.selectBtn {
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                width: 70px;
                height: 30px;
                border: 1px solid $color-gray-light !important;
                box-sizing: border-box;
                border-radius: 5px;
                color: $color-dark;
            }
        }
    }
}

div {
    &.selectBtn {
        display: inline-block !important;
        height: 35px !important;
        position: relative;
        vertical-align: top;
        font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen,
            Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica,
            Arial, sans-serif;
        line-height: 35px !important;
        margin-bottom: 0px !important;
        >div {
            display: none;
            position: absolute;
            border: 1px solid black;
            z-index: 100;
            min-width: 140px;
            ul {
                margin-left: 0px;
                list-style-type: none;
            }
        }
        &:hover {
            >div {
                display: block;
            }
        }
    }
}


.checkSize {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border: 1px solid $color-black;
    box-sizing: border-box;
    vertical-align: middle;
    margin: 10px 10px;
    margin-left: 0;
}

.select:not(.is-multiple) {
    height: 35px;
}

.HRline {
    width: 100%;
    height: 0px;
    left: 255px;
    top: 640px;
    border: 1px solid $color-primary;
}

.clr1 {
    background: #81dfed;
    border: 1px solid #81dfed;
    box-sizing: border-box;
    border-radius: 5px;
    width: 68px;
    margin: 5px 15px;
    height: 22px;
}

.clr2 {
    background: #62b4bf;
    border: 1px solid #62b4bf;
    box-sizing: border-box;
    border-radius: 5px;
    width: 68px;
    height: 22px;
    margin: 5px 15px;
}

.clr3 {
    background: $color-primary;
    border: 1px solid $color-primary;
    box-sizing: border-box;
    border-radius: 5px;
    width: 68px;
    height: 22px;
    margin: 5px 15px;
}

.secondSectionMonthlyDocumnet {
    display: flex;
    align-items: center;
    >div {
        >p {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            color: $color-primary;
        }
    }
}

.ml160 {
    margin-left: 200px;
}

.mt10 {
    margin-top: 10px;
}

.colordtext {
    >div {
        >span {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: $color-primary;
            padding: 0px;
        }
    }
}

.Heading {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $color-primary;
}

.mt5 {
    margin-top: 5px;
}

.headerChart {
    width: 100%;
    height: 96px;
    text-align: center;
    display: flex;
    background: $color-primary;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    background: $color-primary;
    >p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        font-variant: small-caps;
        text-align: center;
        color: $color-white;
    }
}

.contactAgendaInput {
    border: none;
    background-color: transparent;
    margin-left: 10px;
}

.agendaSec1 {
    display: flex;
    >div {
        >p {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 15px;
            color: $color-darken;
            margin: 20px;
        }
    }
}

.checkbox-label {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: $color-darken;
    margin: 20px;
    >span {
        vertical-align: middle;
    }
}
.subHeading {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    color: $color-darken;
}

.agendasec2 {
    display: flex;
    justify-content: space-between;
}
.agenda-table {
    >tr {
        >th {
            font-family: $font-family-base;
            font-size: 13px;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
        }
        >td {
            font-family: $font-family-base;
            font-size: 13px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
        }
    }
}

.colSize1 {
    width: 100px;
}

.checkText {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    color: $color-darken;
}

.textareA {
    width: 100%;
}

table {
    >tr {
        >td {
            color: black;
        }
    }
}

label {
    color: black;
}

.dropdownCustomize {
    width: 40%;
}

p,
label {
    color: black;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.inputagenda {
    border: none;
    border-bottom: 1px solid $color-border;
    margin-left: 15px;
}

.check {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
}

.check {
    >* {
        width: 50%;
        height: 30px;
    }
    >p {
        padding: 10px;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        /* identical to box height */

        color: $color-darken;
    }
}
.inputFlex {
    display: flex;
    justify-content: space-between;
}

.inputagenda2 {
    border: none;
    border-bottom: 1px solid $color-border;
    width: 320px;
}

.agendaTable {
    >table {
        >tr {
            >td {
                height: 25px;
                font-family: $font-family-base;
                font-size: 12px;
                font-weight: 500;
                line-height: 13px;
                letter-spacing: 0em;
                text-align: center;
                color: $color-darken;
                vertical-align: middle;
            }
            th {
                height: 30px;
                text-align: center;
                vertical-align: middle;
            }
        }
    }
}

.pro-sidebar {
    .pro-menu {
        .pro-menu-item {
            &.active {
                color: $color-primary;
                font-weight: bold;
            }
            >.pro-inner-item {
                >.pro-icon-wrapper {
                    margin-right: 10px;
                    font-size: 20px;
                    width: 35px;
                    min-width: 35px;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    display: inline-block;
                }
                >.pro-item-content {
                    flex-grow: 1;
                    font-size: 16px;
                    flex-shrink: 1;
                }
            }
        }
    }
}

.agendaTableHeader {
    font-family: $font-family-base;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: $color-darken;
    vertical-align: middle;
}
.agendaTableText {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: $color-darken;
}
.calendar-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include max-screen($tablet) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }
    .headingMain {
        @include max-screen($tablet) {
            margin: 10px 0 !important;
        }
    }
    button {
        @include max-screen($mobile) {
            margin: 0 !important;
        }
        svg {
            @include max-screen($mobile) {
                margin: 0 !important;
            }
        }
    }
    .grid-top {
        @include max-screen($mobile) {
            margin-top: 10px !important;
        }
    }
}