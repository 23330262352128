@import '../../../scss/variables.scss';
.profileScreen {
    .mt20 {
        margin-top: 20px;
    }
    .profile {
        .primary-button {
            height: 46px;
            width: 204px;
            background: $color-primary;
            border-radius: 30px;
            color: $color-white;
            font-family: $font-family-base;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
            margin-top: 30px;
        }
        .label {
            font-family: $font-family-base;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: $color-primary;

            margin-left: 0px;
            margin-bottom: 6px;
        }
        .text-input {
            font-family: $font-family-base;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: $color-primary;

            height: 36px;
        }
    }
}