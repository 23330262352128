@import '../../../scss/variables.scss';
.logForm {
    background: $color-white;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);
    padding: 20px;
}

.sectiondashboard {
    min-height: 100vh;
    height: 100%;
}

.logForm {
    >label {
        font-family: $font-family-base;
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: $color-darken;
        margin: 0;
        margin-top: 20px;
    }
}

.logForm {
    >input {
        margin-top: 1px;
        border: 1px solid $color-gray-dark;
        box-sizing: border-box;
        border-radius: 5px !important;
        padding: 5px 15px !important;
    }
    >select {
        margin-top: 1px;
        border: 1px solid $color-gray-dark;
        box-sizing: border-box;
        border-radius: 5px !important;
        padding: 5px 10px !important;
    }
    >textarea {
        border: 1px solid $color-gray-dark;
        box-sizing: border-box;
        border-radius: 5px !important;
        padding: 20px !important;
    }
}
.Register__error {
    margin-left: 1%;
    font-size: 12px;
    color: $color-danger;
}

.dropdownCustom {
    box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
    max-width: 100%;
    width: 100%;
    background-color: $color-white;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: $color-primary;
    height: 30px;
}
.column {
    &.is-two-fifths,
    &.is-two-fifths-tablet {
        flex: none;
        width: 45%;
    }
}
.date-picker {
    width: 100%;
    height: 32px;
    border-radius: 5px;
    padding: 0px 15px;
    margin-bottom: 20px;
    border: 1px solid $color-gray-dark;
}

.date-picker:focus {
    border-color: #485fc7;
    box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25);
    outline: none;
}

.MuiOutlinedInput-root {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    padding: 0px;
    border: 1px solid $color-gray-dark;
}
.MuiMultiselect {
    font-family: $font-family-base;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: $color-darken;
}

.month-card {
    width: 45%;
    box-shadow: 0px 3px 9px 0px #00000040;
    margin-left: 10%;
    padding: 0;
    height: fit-content;
    @include max-screen($tablet) {
        width: 100%;
        margin-left: 0%;
    }
    @include max-screen(420px) {
        width: 100%;
    }
    .month {
        border: 1px solid $color-border;
        color: white;
        background-color: $color-primary;
        font-family: $font-family-base;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-border;
    }
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 50px;
        font-family: $font-family-base;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: $color-dark;
        border-bottom: 1px solid $color-border;
        border-right: 1px solid $color-border;
    }
    .check-label {
        font-family: $font-family-base;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        width: 100%;
    }
}

.loghourDiv {
    display: flex;
    @include max-screen(1080px) {
        flex-direction: column;
    }
}
.logHourForm {
    width: 50%;
    @include max-screen(1080px) {
        width: 100%;
    }
}
.headingMain {
    @include min-max-screen(705px, 1080px) {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: $color-primary;
    }
}
.heading_Main {
    @include min-max-screen(705px, 1080px) {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: $color-primary;
    }
}
.headMain {
    @include min-max-screen(320px, 360px) {
        font-size: 20px !important;
    }
}

.log__supervisor button {
    color: $color-primary;
    cursor: pointer;
}

.log__supervisor button:hover {
    text-decoration: underline;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}