@import '../../scss/variables.scss';
.registration {
    @include max-screen($tablet) {
        padding: 10px;
    }
    h1 {
        margin-top: 0;
        text-align: center;
    }
    .Register__error {
        margin-left: 1%;
        color: red;
    }
    .column {
        &:empty {
            @include max-screen($tablet) {
                display: none;
            }
        }
    }
    .terms-checkbox {
        width: 16px;
        height: 16px;
    }
    .register-checkbox-text {
        margin-left: 16px;
        word-wrap: break-word;
        font-size: small;
        text-align: right;
        @include min-max-screen(375px, 420px) {
            word-wrap: break-word;
            font-size: 10px !important;
            text-align: center;
        }
        @include min-max-screen(320px, 360px) {
            word-wrap: break-word;
            font-size: 8px !important;
            text-align: center;
        }
        @include max-screen(780px) {
            color: $color-danger;
        }
        >a {
            text-decoration: none;
            color: $color-primary;
            font-weight: bold;
        }
    }
    .has-left-background {
        min-height: 100vh;
        @include background-image(bg-left, png, left center, no-repeat, auto 100%);
        background-attachment: fixed;
    }
    .supervisor-signup-text {
        margin-left: 2px;
        @include min-max-screen(375px, 420px) {
            margin-left: 0;
        }
        @include min-max-screen(320px, 360px) {
            margin-left: 0px;
        }
    }
    .logo-col {
        @include max-screen($tablet) {
            padding: 20px 0 0;
        }
    }
    .offer-banner {
        width: 100%;
        margin-bottom: 10px;
        h4 {
            font-size: 22px;
            color: $color-black;
            font-weight: 700;
            span {
                color: $color-primary;
            }
        }
    }
    .offer-banner-inner {
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 15px;
        width: 100%;
        max-width: 505px;
        border-radius: 10px;
        text-align: left;
        border: 1px dashed $color-primary;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        background-color: rgba($color-primary-light, 0.3);
        @include max-screen($desktop) {
            display: block;
            padding: 10px 10px 22px;
            text-align: center;
        }
        @include max-screen($tablet) {
            background: rgb(255 255 255 / 92%);
        }
        p {
            padding-left: 10px;
            font-size: 18px;
            @include max-screen($desktop) {
                padding-left: 0;
            }
            strong {
                color: $color-primary;
                font-size: 150%;
            }
        }
    }
    .column.is-half {
        flex: none;
        width: 70%;
    }
    .registration-text {
        @include max-screen(1490px) {
            font-size: 48px;
            align-self: center;
            justify-content: center;
            align-items: center;
        }
        @include max-screen(360px) {
            font-size: 35px;
            margin-left: 12%;
        }
        @include max-screen(780px) {
            color: $color-danger;
        }
    }
    .register-form-layout2 {
        @include max-screen(1490px) {
            margin-left: 0%;
        }
    }
    .register-terms-layout {
        @include min-max-screen(1100px, 1490px) {
            margin-left: 5%;
            width: 80%;
            color: $color-darken;
        }
    }
    .register-terms-layout2 {
        @include min-max-screen(1100px, 1490px) {
            margin-left: 20px;
            width: 80%;
            color: $color-darken;
        }
    }
    .checkbox {
        @include max-screen(1490px) {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 500;
            color: $color-darken;
            font-size: 14px;
        }
        @include max-screen(420px) {
            font-size: 9px !important;
        }
    }
    .checkbox2 {
        @include min-max-screen(1100px, 1490px) {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 500;
            color: $color-darken;
            font-size: 14px;
            margin-left: 0;
        }
    }
    .signup-text {
        @include min-max-screen(1100px, 1490px) {
            font-size: 12px;
            margin-top: -20px;
        }
        @include max-screen(420px) {
            font-size: 14px;
        }
    }
    .supervisor-signup-text {
        @include min-max-screen(770px, 1490px) {
            margin-left: 0px;
        }
    }
    .column {
        &.is-half {
            @include min-max-screen(548px, 1080px) {
                flex: none;
                width: 70%;
            }
            @include max-screen(540px) {
                width: 100%;
            }
        }
    }
}