@import '../../../../scss/variables.scss';
.backlink {
    color: $color-primary;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 17px;
    margin-right: 10px;
    margin-left: -20px;
}
.ml50 {
    margin-left: 50px;
    @include max-screen(980px) {
        margin-left: 12px;
    }
}
.editText {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    color: $color-primary;
}

.panel {
    box-shadow: 0 0.5em 1em -0.125em transparent;
    font-size: 1rem;
}

.price {
    display: flex;
    justify-content: center;
}

.price sub {
    margin-top: 0.5rem;
}

.subsCard {
    @include max-screen(980px) {
        display: flex;
        flex-direction: column;
    }
}
.headingMain {
    @include min-max-screen(538px, 980px) {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        color: $color-darken;
        line-height: 32px;
    }
}
.panel-head {
    @include min-max-screen(320px, 360px) {
        width: 269px;
        height: 217px;
        border: 3px solid $color-primary;
        box-sizing: border-box;
        border-radius: 20px;
    }
}