@import '../../scss/variables.scss';
.faq {
    width: 100%;
    padding: 3rem 6rem;
    @include max-screen(991px) {
        padding: 3rem;
    }
    @include max-screen(767px) {
        padding: 1.5rem;
    }
    ul {
        max-width: 800px;
        margin: 40px auto 0;
        p {
            color: #333333;
            padding-top: 10px;
            line-height: 1.5;
            white-space: pre-line;
        }
        li {
            padding: 15px 20px;
            border-radius: 10px;
            border: 1px solid #eaeaea;
            margin-bottom: 20px;
            position: relative;
            &:last-child {
                margin-bottom: 0;
            }
            button {
                cursor: pointer;
                position: absolute;
                top: 17px;
                background-color: #eaeaea;
                right: 20px;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                font-size: 18px;
                padding: 0 0 1px 0;
                line-height: 18px;
                display: block;
                color: #545454;
            }
            a {
                text-decoration: none;
                font-weight: 700;
                color: $color-black;
                font-size: 16px;
                display: block;
                width: calc(100% - 30px);
                cursor: pointer;
                @include max-screen(767px) {
                    font-size: 14px;
                }
                &.active {
                    color: $color-primary;
                }
            }
        }
    }
}
.faqHeading {
    text-align: center;
    h3 {
        font-size: 34px;
        padding-bottom: 10px;
        line-height: 1.25;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        @include max-screen(991px) {
            font-size: 28px;
        }
        img {
            max-width: 60px;
            margin: 5px 0 5px 5px;
            transform: rotate(0deg);
            vertical-align: bottom;
            @include max-screen(991px) {
                max-width: 50px;
            }
            @include max-screen(767px) {
                max-width: 40px;
            }
        }
        p {
            font-size: 14px;
            color: #7d7d7d;
        }
    }
}