@import '../../../scss/variables.scss';
.upload__delete-btn {
    cursor: pointer;
    transition: all 0.5s ease;
    margin-left: 0.25rem;
    &:hover {
        transform: scale(1.25);
    }
}

.fieldwor-froms {
    width: 50%;
}

.list-flex {
    display: flex;
    list-style-type: none;
    margin-left: 0;
    @include max-screen($mobile) {
        display: block;
    }
    li {
        @include max-screen($mobile) {
            margin-bottom: 10px;
        }
    }
}

.a_style_download {
    padding: 0;
    >button {
        display: flex;
        align-items: center;
        color: $color-white;
        font-weight: 700;
        font-size: 1em;
        text-align: left;
        background-color: $color-primary;
        font-weight: 700;
        color: $color-white;
        padding: 1rem;
        border-radius: 13px;
        min-width: 200px;
        max-width: 220px;
        >img {
            margin-right: 10px;
        }
        &:hover {
            cursor: pointer;
            background-color: darken($color-primary, 10%);
        }
    }
}

.redirect {
    color: $color-primary;
    text-decoration: underline;
}

.teal-button {
    text-decoration: none !important;
    background-color: $color-primary;
    color: white;
    padding: 16px;
    border-radius: 13px;
    &:hover {
        background-color: $color-primary;
    }
}

a.button {
    img {
        height: 16px;
        margin-right: 10px;
        svg {
            color: white;
        }
    }
}
.mr15 {
    margin-right: 15px;
}

.attachment {
    @include min-max-screen(548px, 768px) {
        background: rgba(247, 233, 205, 0.3);
        border: 1px dashed lighten($color-black, 76.86);
        box-sizing: border-box;
        width: 430px !important;
        height: 190px;
    }
    @include min-max-screen(425px, 700px) {
        width: 360px !important;
        height: 190px;
    }
    @include min-max-screen(375px, 420px) {
        width: 330px !important;
        height: 190px;
    }
    @include min-max-screen(320px, 360px) {
        width: 270px !important;
        height: 190px;
    }
}

.fieldwor-froms {
    @include max-screen(767px) {
        width: 100%;
    }
}