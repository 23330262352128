@mixin max-screen($screenSize) {
    @media (max-width: $screenSize) {
        @content;
    }
}

@mixin min-screen($screenSize) {
    @media (min-width: ($screenSize+1)) {
        @content;
    }
}
@mixin min-max-screen($screenMinSize, $screenMaxSize) {
    @media screen and (min-device-width: $screenMinSize) and (max-device-width: $screenMaxSize) {
        @content;
    }
}

@mixin background-image($img, $ext, $pos, $repeat: no-repeat, $size: auto) {
    background-image: url(#{$imgPath}#{$img}.#{$ext});
    background-size: $size;
    background-position: $pos;
    background-repeat: $repeat;
}