@import '../../scss/variables.scss';
.forgot-password-text-sub {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $color-darken;
    @include max-screen(360px) {
        font-size: 12px;
        margin-left: 10px;
    }
}

.cancel-text {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 15px;
    text-align: left;
    color: $color-primary;
    text-decoration: underline;
    @include max-screen(780px) {
        color: $color-danger;
    }
}
.welcome-text {
    @include min-max-screen(375px, 420px) {
        font-size: 40px;
    }
}