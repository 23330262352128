$color-white: #fff;
$color-black: #000;
$color-primary: #016355; //
$color-primary-light: #9bdd9b; //
$color-dark: #2f2e2e; //
$color-darken: #141414; //
$color-darkest: #101010; //
$color-gray: #9b9b9b; // 
$color-gray-dark: #4b2929; //
$color-gray-lighten: #ddd; //
$color-gray-light: #dcd9d9;
$color-gray-medium: #a4a4a4; //
$color-light: #ffeeaf; //
$color-border: #b7b7b7; //
// state colors
$color-warning: #9bdd9b; //
$color-warning-light: #7ec57e;
$color-info: #06917c; //
$color-danger: #f9433e; //

// utilities
$font-family-base: 'Montserrat', Arial, sans-serif;
$font-family-secondary: 'Suranna', Arial, sans-serif;

// breakpoints
$small: 576px;
$mobile: 767px;
$tablet-sm: 992px;
$tablet: 1023px;
$desktop: 1199px;
$desktop-lg: 1279px;
$desktop-xl: 1440px;

// image path
$imgPath: '../assets/';

// mixins
@import 'mixins';

// inline variables
:root {
    --color-white: #fff;
    --color-primary: #016355;
    --color-primary-light: #9bdd9b;
    --color-dark: #2f2e2e;
    --color-gray-dark: #4b2929;
    --color-gray-lighten: #ddd;
    --color-light: #ffeeaf;
    --color-border: #b7b7b7;

    /* state colors */
    // --color-warning: #f9e18a;
    --color-warning: #9bdd9b;
    // --color-warning-dark: #ffba2e;
    --color-warning-dark: #7ec57e;
    --color-info: #06917c;
    --color-danger: #f9433e;
}