@import '../../../scss/variables.scss';
.sectiondashboard {
    display: flex;
    height: 100vh;
}

.pro-sidebar {
    .pro-menu-item {
        >.pro-inner-item {
            >.pro-icon-wrapper {
                background-color: transparent;
            }
            &:hover {
                color: $color-dark !important;
            }
        }
    }

    >.pro-sidebar-inner {
        >.pro-sidebar-layout {
            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                margin-top: 45px;
            }
            .pro-sidebar-header {
                border-bottom: none;
            }
        }
    }
    .pro-menu {
        .pro-menu-item {
            font-size: 15px;
            margin-top: 10px;
        }
    }
}

.mb30 {
    margin-bottom: 30px;
}

.colorDarkPink {
    color: $color-primary;
}

h1,
h2,
h3 {
    color: $color-darken;
}

a {
    font-weight: 700;
    text-decoration: underline;
    color: $color-primary;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}
*,
::after,
::before {
    box-sizing: inherit;
}
user agent stylesheet li {
    display: list-item;
    text-align: -webkit-match-parent;
}

ul {
    list-style: none;
}
ul {
    list-style: none;
}
user agent stylesheet ul {
    list-style-type: disc;
}
.pro-sidebar {
    color: $color-dark;
}
.headingMain {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: $color-dark;
}

.divider {
    border-bottom: 2px solid $color-primary;
    width: 600px;
    max-width: 100%;
}

.pera {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $color-dark;
}

.heading2nd {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 29px;
    color: $color-dark;
    @include max-screen($mobile){
        font-size: 30px;
    }
}

.text-primary {
    color: $color-primary;
}
.heading3rd {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    color: $color-dark;
}

.mt40 {
    margin-top: 40px;
}
.mt60 {
    margin-top: 60px;
}
.mt30 {
    margin-top: 30px;
}
.mt80 {
    margin-top: 80px;
}

.max-width-449 {
    max-width: 449px;
    width: 100%;
}

.hr-primary {
    background-color: $color-primary;
}

.subscription__dashboard--h3 {
    line-height: initial;
    margin-bottom: 0.5rem;
}

.dashBtn {
    background-color: $color-primary;
    color: $color-white;
    width: 220px;
    margin: 0 auto;
    display: flex;
}

.button-bottom {
    text-align: unset;
    @include max-screen(980px) {
        text-align: center;
    }
}
.changelink {
    color: $color-primary;
    text-decoration: underline;
    font-weight: 700;
    margin-top: 0.5rem;
}

.cancelLink {
    color: #d91e3f;
    text-decoration: underline;
    font-weight: 700;
}

.pro-sidebar .pro-menu {
    /* margin-top: 50px; */
    padding-top: 10px;
    padding-bottom: 10px;
}

.headingInfinity {
    font-size: 6rem;
    line-height: 3rem;
}