@import '../../../scss/variables.scss';
.column {
    &.is-two-fifths,
    &.is-two-fifths-tablet {
        flex: none;
        width: 45%;
    }
}
.flagged-logs {
    padding-left: 16px;
    >p {
        font-family: $font-family-base;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: $color-primary;

        margin-bottom: 24px;
    }
    .Flagtable {
        margin-bottom: 30px;
        >thead {
            >tr {
                >th {
                    font-family: $font-family-base;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: center;
                    background-color: rgba($color-primary-light, 0.3);
                    border: none;
                    color: $color-darken;
                }
            }
        }
        >tbody {
            >tr {
                >td {
                    font-family: $font-family-base;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: $color-darken;
                    vertical-align: middle;
                    height: 45px;
                }
            }
        }
    }
    .name {
        font-family: $font-family-base;
        font-size: 14px;
        font-weight: 500;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;
        color: $color-darken;
    }
    .tableFlex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 230px;
    }
    .text-button {
        height: 28px;
        width: auto;
        background: $color-white;

        border-radius: 30px;
        color: $color-primary;

        font-family: $font-family-base;
        font-size: 14px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
    }
    .primary-button {
        height: 28px;
        width: 70px;
        background: $color-primary;
        border-radius: 30px;
        color: $color-white;
        font-family: $font-family-base;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
    }
    .secondary-button {
        height: 28px;
        width: 70px;
        background: $color-primary;
        border-radius: 30px;
        color: $color-white;
        font-family: $font-family-base;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
    }
}

.delete-dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 233px;
    border-radius: 0px;
    box-shadow: 0px 4px 6px 0px #00000040;
    background: $color-white;

    padding: 10px;
    >p {
        font-family: $font-family-base;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        color: $color-darken;
    }
    .primary-button {
        height: 28px;
        width: 45%;
        background: $color-primary;
        border-radius: 30px;
        color: $color-white;
        font-family: $font-family-base;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
    }
    .secondary-button {
        height: 28px;
        width: 45%;
        background: $color-primary;
        border-radius: 30px;
        color: $color-white;
        font-family: $font-family-base;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
    }
}

.row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.headingMain {
    @include min-max-screen(770px, 1080px) {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: $color-primary;
    }
}
.heading_Main {
    @include min-max-screen(770px, 1080px) {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: $color-primary;
    }
}