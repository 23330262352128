@import '../../scss/variables.scss';
.profilePage {
    .mt20 {
        margin-top: 20px;
    }

    .mt60 {
        margin-top: 60px;
    }

    .badges {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 25px;
        padding-bottom: 20px;
    }

    @include max-screen(1234px) {
        .badges {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @include max-screen($desktop) {
        .badges {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @include max-screen(950px) {
        .badges {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .mainBadges {
        /* height: 305px; */
        background: $color-white;
        box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);
        padding: 20px;
    }

    .mainBadges>p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
    }

    .nestedDivBadges {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        img {
            width: 40px;
            height: 40px;
            object-fit: contain;
        }
    }

    .mt50 {
        margin-top: 40px;
    }
    .nestedDivBadges>p {
        margin-top: 15px;
    }

    .light {
        opacity: 0.2;
    }
    .dark {
        opacity: 1;
    }

    .badge-icon {
        width: 58px;
        height: auto;
    }
    .faq-wrap {
        display: flex;
        align-items: center;
        >img {
            width: 35px;
            margin-left: 10px;
        }
    }
    .faq-container {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $color-primary;
        border-radius: 10px;
        padding: 5px 10px;
        text-decoration: none;
        background-color: rgba($color-primary, 0.1);
        &:hover {
            color: $color-primary;
        }
        img {
            margin-left: 10px;
            width: 20px;
        }
    }
    .profile-block {
        border-radius: 50%;
        background-color: $color-white;
    }
    .profile-block-holder,
    .profile-block {
        width: 150px;
        height: 150px;
        position: relative;
    }
    .profile-block-holder {
        display: flex;
        align-items: center;
        padding: 25px;
        border-radius: 50%;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.25);
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    .profile-block {
        >img {
            width: 150px;
            height: 150px;
            object-fit: cover;
            border: 1px solid rgba(0, 0, 0, 0.25);
            border-radius: 50%;
        }
        .profile-edit {
            overflow: hidden;
            position: absolute;
            right: 20px;
            bottom: 3px;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: lighten($color-black, 60.00);
            display: flex;
            align-items: center;
            padding: 5px;
            cursor: pointer;
            justify-content: center;
            img {
                max-width: 100%;
                max-height: 100%;
                cursor: pointer;
            }
        }
        input {
            position: absolute;
            left: 0;
            top: 0;
            cursor: pointer;
            opacity: 0;
            z-index: -1;
        }
    }


    .profile-block-loader {
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        justify-content: center;
        position: absolute;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.3);
        &::after {
            content: "";
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 5px solid #999;
            border-left-color: #ffd0df;
            border-right-color: #ffd0df;
            animation: spin 2s linear infinite;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}