@import '../../scss/variables.scss';
.customModal {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1010;
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    &__content {
        z-index: 1;
        overflow: hidden;
        max-width: 600px;
        position: relative;
        border-radius: 10px;
        width: calc(100% - 20px);
        background-color: $color-white;
    }
    &__backdrop {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
    }
    &__close {
        top: 10px;
        right: 10px;
        width: 12px;
        opacity: 0.4;
        z-index: 2;
        height: 12px;
        cursor: pointer;
        position: absolute;
    }
    &__inner {
        min-height: 100px;
    }
    &.active {
        opacity: 1;
        visibility: visible;
    }
}