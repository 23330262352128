@import '../../scss/variables.scss';
.confirmationBlock {
    max-height: 90vh;
    overflow-y: auto;
    text-align: center;
    padding: 50px 20px;
    @include max-screen($mobile) {
        padding: 50px 20px 30px;
    }
    h2 {
        font-size: 25px;
        line-height: 1.1;
        font-weight: 700;
        margin: 0 0 15px;
        @include max-screen($mobile) {
            font-size: 20px;
        }
    }
    p {
        font-size: 18px;
        color: #888686;
        margin-bottom: 30px;
        @include max-screen($mobile) {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
    img {
        width: 120px;
        margin-bottom: 10px;
        @include max-screen($mobile) {
            width: 80px;
        }
    }
}