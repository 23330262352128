@import '../../../scss/variables.scss';
.backlink {
    color: $color-primary;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 17px;
    margin-right: 10px;
    margin-left: -20px;
}

.ml50 {
    margin-left: 50px;
    @include max-screen(980px) {
        margin-left: 12px;
    }
}

.editText {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    color: $color-primary;
}

.comingSoon {
    padding: 10px 20px;
    background-color: var(--color-warning);
    display: inline-block;
    text-align: center;
    margin: auto;
    @include max-screen($desktop) {
        width: 100%;
    }
}
.comingSoon-wrap {
    @include max-screen($desktop) {
        flex-wrap: wrap;
    }
}

.base-content {
    display: flex;
    flex-direction: column;
}

.item-c {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.row-start-sub {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}

.panel {
    box-shadow: 0 0.5em 1em -0.125em transparent;
    font-size: 1rem;
}

.price {
    display: flex;
    justify-content: center;
}

.price sub {
    margin-top: 0.5rem;
}

.line {
    border-bottom: 3px solid #d39fb2;
    width: 97%;
    height: 0.5em;
}

.current-plan {
    display: flex;
    padding: 0 1em;
    position: relative;
    margin-top: -17px;
    align-items: center;
    justify-content: center;
}

.current-subscription {
    white-space: nowrap;
    font-weight: bold;
    font-size: 18px;
    padding: 0 0.5em;
}

.subscriptionPlan {
    margin-top: 30px;
}
.subscriptionPlan ol {
    margin-left: 15px;
}
.subsCard {
    @include min-max-screen(320px, 980px) {
        display: flex;
        flex-direction: column;
    }
}
.headingMain {
    @include max-screen($tablet) {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        color: $color-darken;
        line-height: 32px;
    }
}
.panel-head {
    @include max-screen(360px) {
        width: 269px;
        height: 217px;
        border: 3px solid $color-primary;
        box-sizing: border-box;
        border-radius: 20px;
    }
}